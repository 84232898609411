<template>
    <div class="gst-event-post-purchase">
        <DataLoading v-if="loading" class="my-5 py-5" />
        <div v-if="!loading" class="d-flex flex-column u-height-100">
            <div class="d-flex flex-column u-height-100">
                <EventBanner
                    v-if="showEventBanner"
                    :item="event" />
                <div v-if="loading">
                    <DataLoading class="my-5 py-5" />
                </div>
                <template v-else-if="!isEventNotAvailable">
                    <EventContent
                        :event="event"
                        :is-event-banner-visible="showEventBanner"
                        :bus-event-parent="busEvent"
                        :is-no-event="isNoEvent"
                        @checkout="checkout"
                        @selected-hotel-reservation="onSelectedHotelReservationDo" />
                </template>
                <template v-else>
                    <EventNotAvailable
                        :event="event"
                        :is-offer-expired="isEventTicketsNotAvailable" />
                </template>
            </div>
        </div>
        <router-view />
        <div v-if="googleAnalyticsData">
            <GoogleAnalyticsTracker :custom-arguments="googleAnalyticsData" />
        </div>
        <div v-if="facebookAnalyticsData">
            <FacebookAnalyticsTracker :custom-arguments="facebookAnalyticsData" />
        </div>
        <div v-if="tradeDeskAnalyticsData">
            <TradeDeskAnalyticsTracker :query-params="tradeDeskAnalyticsData" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Vue from 'vue';
    import apiConstants from '@core/utils/constants/api';
    import eventConstants from '@core/utils/constants/event';
    import offerConstants from '@core/utils/constants/offer';
    import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
    import {
        isPast as eventUtilsIsPast
    } from '@core/utils/eventUtils';
    import EntityNotFoundRedirect404 from '@core/mixins/EntityNotFoundRedirect404';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import ApiCancelService from '@core/services/ApiCancelService';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import tmEventConstants from '@tenant/app/utils/constants/event';
    import GoogleAnalyticsTracker from '@tenant/app/components/analytics/GoogleAnalyticsTracker.vue';
    import FacebookAnalyticsTracker from '@tenant/app/components/analytics/FacebookAnalyticsTracker.vue';
    import TradeDeskAnalyticsTracker from '@tenant/app/components/analytics/TradeDeskAnalyticsTracker.vue';
    import AnalyticsDataTrackerMixin from '@tenant/app/mixins/AnalyticsDataTracker.js';
    import EventBanner from './TheEventPostPurchase/_components/EventBanner.vue';

    const STORE_NAME = 'events';

    export default {
        name: 'TheEventPostPurchase',
        components: {
            EventBanner,
            DataLoading,
            GoogleAnalyticsTracker,
            FacebookAnalyticsTracker,
            TradeDeskAnalyticsTracker,
            EventContent: ( ) => import( './TheEventPostPurchase/EventContent.vue' ),
            EventNotAvailable: ( ) => import( './TheEventPostPurchase/EventNotAvailable' ),
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent'
        },
        testIdOptions: {
            keyPrefix: 'newEvent'
        },
        mixins: [
            EntityNotFoundRedirect404,
            ViewDocumentTitleMixin,
            AnalyticsDataTrackerMixin
        ],
        data( ) {
            return {
                loading: true,
                hotels: [ ],
                previousRoute: { },
                busEvent: new Vue( ),
                selectedHotelReservation: null,
                showEventBanner: false
            };
        },
        computed: {
            ...mapGetters( {
                isEventUnknown: `${STORE_NAME}/isUnknown`,
                event: `${STORE_NAME}/getEvent`
            } ),
            hasHealthCheck( ) {
                return !!this.event.healthCheck;
            },
            routeId( ) {
                return this.$route.params.id;
            },
            routeView( ) {
                return this.$route.params.view;
            },
            eventId( ) {
                return this.event && this.event.id;
            },
            isUsingLegacyRoute() {
                return this.$route.name === 'tm-eventHotels';
            },
            isEventTicketsNotAvailable( ) {
                const { eventStatus, active } = this.event;

                return !active
                    || eventStatus === eventConstants.EVENT_STATUS.CANCELLED
                    || eventUtilsIsPast( this.event );
            },
            isEventNotAvailable( ) {
                return  !this.isEventUnknown && this.isEventTicketsNotAvailable && !this.isNoEvent;
            },
            isNoEvent( ) {
                return this.$route.name === 'hotels';
            }
        },
        watch: {
            '$route.params.id': function( ) {
                this.loadData( );
            },
            '$route.query.city': function( ) {
                this.setDocumentTitle( );
            }
        },
        methods: {
            ...mapActions( {
                loadOne                         : `${STORE_NAME}/one`,
                loadOneByLegacy                 : `${STORE_NAME}/oneByLegacy`,
                setEventFlow: `${STORE_NAME}/setEventFlow`,
                setNoEvent: `${STORE_NAME}/setNoEvent`,
                notificationError               : 'notification/error',
                notificationWarning             : 'notification/warning',
                setProductId: 'addHotelReservationState/hotels/setProductId',
                setOfferType: 'addHotelReservationState/hotels/setOfferType',
            } ),
            async loadData( ) {
                this.loading = true;
                const event = !this._destroyed && await this.loadEvent( );
                this.loading = false;
                if ( !this.eventId ) {
                    if ( this.isEventUnknown ) {
                        // allow user to search for hotels for unknown event
                        this.setEventFlow( { flow: tmEventConstants.FLOWS.HOTELS_OPEN_INVENTORY } );
                    } else {
                        // for other errors redirect the user as before
                        this._goToNotFoundPage( );
                    }
                    return;
                }
                // put the event id in the hotels store so it will fetch pre-allocated rooms
                this.setProductId( event.id );
                this.setOfferType( offerConstants.TYPES.HOTEL_ONLY );
                this.setEventFlow( { flow: tmEventConstants.FLOWS.HOTELS_PRE_ALLOCATED } );
            },
            async loadEvent( ) {
                const getEventData = ( ) => {
                    if ( this.isUsingLegacyRoute ) {
                        return this.loadOneByLegacy( { id: this.routeId } );
                    }
                    return this.loadOne( this.routeId );
                };
                const event = await getEventData( );

                return event;
            },
            setDocumentTitle( ) {
                if ( this.isEventUnknown ) {
                    const { city, stateCode } = this.$route.query;
                    if ( city ) {
                        this.updateDocumentTitle( this.$t( 'main:viewsTitle.theEventPostPurchase.unknownEvent', { location: [ city, stateCode ].filter( item => item ).join( ', ' ) } ) );
                    }
                } else {
                    this.updateDocumentTitle( this.$t( 'main:viewsTitle.theEventPostPurchase.event', { event: this.event.name } ) );
                }
            },
            openHotelRoomRateChangedModal( response ) {
                const { detail } = response;
                const currency = this.selectedHotelReservation ? this.selectedHotelReservation.item.selectedRoom.selectedRate.currency : this.event.currency;
                const hotelRoomOldTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.oldTotalPrice, currency );
                const hotelRoomNewTotalPrice = this.$options.filters.currencyFilter( detail.hotelRoom.newTotalPrice, currency );
                const giftCardAmountOld = detail.giftCard.oldAmount;
                const giftCardAmountNew = detail.giftCard.newAmount;
                const newTotalPrice = this.$options.filters.currencyFilter( detail.total.newTotalPrice, currency );
                const i18nPrefix = 'main:views.eventV2.theEvent._components.eventHotelRoomRateChangedModal';
                const title = this.$t( `${i18nPrefix}.title` );
                const showContentWithGiftCard = !!giftCardAmountOld || !!giftCardAmountNew;
                let content;
                if ( showContentWithGiftCard ) {
                    content = this.$t(
                        `${i18nPrefix}.contentWithGiftCard`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                            newTotalPrice: newTotalPrice,
                            newGiftCardValue: this.$options.filters.currencyFilter( giftCardAmountNew, currency ) 
                        } );
                } else {
                    content = this.$t(
                        `${i18nPrefix}.content`,
                        {
                            oldPrice: hotelRoomOldTotalPrice,
                            newPrice: hotelRoomNewTotalPrice,
                            newTotalPrice: newTotalPrice
                        } );
                }
                const cancelButton = this.$t( `${i18nPrefix}.buttons.cancel` );
                const confirmButton = this.$t( `${i18nPrefix}.buttons.confirm` );

                this.$modal.show(
                    ( ) => import( '@tenants/ticketmaster/app/components/modals/ConfirmationModal' ),
                    {
                        title,
                        content,
                        cancelButton,
                        confirmButton,
                        dataTestId: 'priceChangeConfirmation',
                        onCancelFn: ( ) => this.busEvent.$emit( 'event-hotel-room-rate-changed-back' ),
                        onConfirmFn: ( ) => this.busEvent.$emit( 'event-hotel-room-rate-changed-next', detail.hotelRoom.newTotalPrice, detail.hotelRoom.oldTotalPrice, detail.giftCard.newAmount, detail.hotelRoom.newRateId )
                    },
                    {
                        'max-width': 858,
                        persistent: true,
                    }
                );
            },
            async checkout( payload ) {
                const callbackAddItemToCartErrorFn = ( response ) => {
                    const { code } = response;

                    const i18Prefix = '_common:messageServerErrorCodesFromContext.reserve';
                    switch ( code ) {
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.PRODUCT_ERROR:
                            this.notificationWarning( {
                                title: this.$t( `${i18Prefix}.${code}.title` ),
                                content: this.$t( `${i18Prefix}.${code}.detail` ),
                                autoDismiss: false
                            } );
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_REQUEST:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.INVALID_DATA:
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.SYSTEM_ERROR:
                            this.notificationError( {
                                title: this.$t( `${i18Prefix}.hotelProviderDefault.title` ),
                                content: this.$t( `${i18Prefix}.hotelProviderDefault.detail` )
                            } );
                            break;
                        case apiConstants.ERROR_CODES.EVENT_PROVIDER.TICKET_ERROR:
                            this.notificationError( notificationUtilsGetFromApiResponse( response ) );
                            break;
                        case apiConstants.ERROR_CODES.HOTEL_PROVIDER.ROOM_RATE_CHANGED:
                            this.openHotelRoomRateChangedModal( response );
                            break;
                        case apiConstants.ERROR_CODES.PACKAGE_OFFERS_NOT_AVAILABLE:
                            this.notificationError( {
                                title: this.$t( `${i18Prefix}.${code}.title` ),
                                content: this.$t( `${i18Prefix}.${code}.detail` ),
                                autoDismiss: false
                            } );
                            break;
                        default:
                            this.notificationError( notificationUtilsGetFromApiResponse( response ) );
                            break;
                    }
                };
                this.$router.push( { name: `${this.$route.name}.reservation`, params: { payload, callbackAddItemToCartErrorFn } } );
            },
            onSelectedHotelReservationDo( value ) {
                this.selectedHotelReservation = value;
            }
        },
        async mounted( ) {

            if ( this.routeId ) {
                await this.loadData( );
                this.setNoEvent( false );
            } else {
                this.setNoEvent( true );
                this.setEventFlow( { flow: tmEventConstants.FLOWS.HOTELS_OPEN_INVENTORY } );
                this.loading = false;
            }

            this.showEventBanner = this.$route.query.showEventInfo === 'true' && !this.isEventUnknown;

            this.busEvent.$on( 'more-info', this.openHotelDetails );
            this.setDocumentTitle( );
        },
        destroyed( ) {
            ApiCancelService.clear( 'events.one' );

            this.busEvent.$off( 'more-info' );

            this._destroyed = true;
        },
        beforeRouteEnter( to, from, next ) {
            next( ( cmp ) => {
                cmp.previousRoute = from;
            } );
        },
        beforeRouteUpdate( to, from, next ) {
            this.previousRoute = from;
            next( );
        },
    };
</script>

<style lang="scss">
    @import '@scssVariables';
    @import '@scssMixins';

    .gst-event-post-purchase {
        height: calc( 100vh - 50px );

        .gst-add-hotel-reservation-hotel-list-card {
            .gst-add-hotel-reservation-hotel-list-card__room-name,
            .gst-add-hotel-reservation-hotel-list-card__bundle-products,
            .gst-add-hotel-reservation-hotel-list-card__btn {
                display: none !important;
            }
        }
    }

    @include mobile-only {
        .gst-event-post-purchase {
            height: calc( var( --vh, 1vh ) * 100 - 50px );
        }
    }
</style>
